export default {
  sentryDSN: 'https://8d73554f6bed4ba8b1e58daa2927a449@sentry.io/1337470',
  sentryReleasePrefix: 'defaults@',
  apiEndpoint: 'https://api-defaults.staging.shootsta.com/',
  serviceEndpoint: 'https://service-defaults.staging.shootsta.com/',
  subscriptionsEndpoint: 'wss://service-defaults.staging.shootsta.com/subscriptions',
  ssoApiEndpoint: 'https://sso-staging.shootsta.com/saml',
  shootstaDomainSegments: 'develop,staging,app,0c7d217c-7ffa-4c88-a893-196e701f3c65',
  skipNotifications: 'false'
};
